import { Dropdown, MenuProps, Select, Tooltip } from 'antd'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { RootState } from '../../../app/store'

import LOGO from '../../../assets/images/logo.png'
import Define from '../../../constants/define'
import { Page, RootPage } from '../../../enum'
import { PagingEnum } from '../../../enum/PagingEnum'
import { ModalName } from '../../../features/stock/modal/customModalSlice'
import { useChangeStockHook } from '../../../hook/changeStockHook'
import { clearClient } from '../../../redux/reducers/accountSlice'
import { renderDigitsByNumber } from '../../../utils'
import './Nav.scss'
import StockNavIcon from '../../../assets/icons/StockNavIcon'
import CommandeNavIcon from '../../../assets/icons/CommandeNavIcon'
import AttenduNavIcon from '../../../assets/icons/AttenduNavIcon'
import HistoryNavIcon from '../../../assets/icons/HistoryNavIcon'
import MissionsNavIcon from '../../../assets/icons/MissionsNavIcon'
import { logout } from '../../../http/clientAPI'
import packageInfo from '../../../../package.json'
import usePermissions from '../../../hook/usePermissions'
import useValidAccount from '../../../hook/useValidAccount'
import { isPermissionEnabled, isSuperAdmin } from '../../../utils/permissions'
import NotiBell from '../../../features/notification/NotiBell'

export interface INavBarProps {}

/**
 * The @NavBar is a component display the navbar of the web. It includes Stock, Inventory, Configuration, Factory.
 * @author [anhnq]
 * @version 0.1
 * @since 16-Mar
 */
export function NavBar(props: INavBarProps) {
  const username = localStorage.getItem(Define.USERNAME)
  const { name } = useSelector((state: RootState) => state.modal)
  const { currentPage, rootPage } = useSelector(
    (state: RootState) => state.page
  )
  const { active, isWMSPermission } = useSelector(
    (state: RootState) => state.permission
  )
  const {
    canAccessStock,
    canAccessAttendu,
    canAccessCommande,
    canAccessMission,
    canAccessHistory,
    canAccessClient,
    canAccessUser,
    canAccessReference,
    canAccessCompany,
    canAccessWarehouse,
    canAccessBilling,
    canAccessInventory,
    canAccessParametres,
  } = usePermissions()

  const items: MenuProps['items'] = []
  if (canAccessStock) {
    items.push({
      key: '1',
      label: (
        <Link
          id="gestiondeStock_stock"
          className="flex items-center"
          to={`/gestion-de-stock/stock?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          <StockNavIcon />
          <div className="ml-2">{t('stock')}</div>
        </Link>
      ),
    })
  }
  if (canAccessAttendu) {
    items.push({
      key: '2',
      label: (
        <Link
          id="gestiondeStock_attendu"
          className="flex items-center"
          to={`/gestion-de-stock/attendu?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          <AttenduNavIcon />
          <div className="ml-2">{t('attendus')}</div>
        </Link>
      ),
    })
  }
  if (canAccessCommande) {
    items.push({
      key: '3',
      label: (
        <Link
          id="gestiondeStock_commande"
          className="flex items-center"
          to={`/gestion-de-stock/commande?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          <CommandeNavIcon />
          <div className="ml-2">{t('commandes')}</div>
        </Link>
      ),
    })
  }
  if (canAccessMission) {
    items.push({
      key: '4',
      label: (
        <Link
          id="gestiondeStock_mission"
          className="flex items-center"
          to={`/gestion-de-stock/mission?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          <MissionsNavIcon />
          <div className="ml-2">{t('missions')}</div>
        </Link>
      ),
    })
  }
  if (canAccessHistory) {
    items.push({
      key: '5',
      label: (
        <Link
          id="gestiondeStock_history"
          className="flex items-center"
          to={`/gestion-de-stock/history?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          <HistoryNavIcon />
          <div className="ml-2">{t('historique')}</div>
        </Link>
      ),
    })
  }

  const itemsConfig: MenuProps['items'] = []
  if (canAccessClient) {
    itemsConfig.push({
      key: '0',
      label: (
        <Link
          id="config_client"
          to={`/configurations/client?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          Clients
        </Link>
      ),
    })
  }
  if (canAccessCompany) {
    itemsConfig.push({
      key: '1',
      label: (
        <Link
          id="config_societe"
          to={`/configurations/societe?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          Sociétés
        </Link>
      ),
    })
  }
  if (canAccessUser) {
    itemsConfig.push({
      key: '2',
      label: (
        <Link
          id="config_utilisateur"
          to={`/configurations/utilisateur?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          Utilisateurs
        </Link>
      ),
    })
  }
  if (canAccessReference) {
    itemsConfig.push({
      key: '3',
      label: (
        <Link
          id="config_reference"
          to={`/configurations/reference?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
        >
          Références
        </Link>
      ),
    })
  }
  if (canAccessWarehouse) {
    itemsConfig.push({
      key: '4',
      label: (
        <div
          id="config_entrepot"
          onClick={() => {
            window.location.href = `${
              process.env.REACT_APP_WAREHOUSE_URL
            }/configuration?client=${localStorage.getItem(
              Define.CHOOSING_CLIENT
            )}&warehouse=${localStorage
              .getItem(Define.CHOOSING_WAREHOUSE)
              ?.replace('ware_', '')}&company=${localStorage.getItem(
              Define.CHOOSING_COMPANY
            )}`
          }}
        >
          Entrepôts
        </div>
      ),
    })
  }
  if (true) {
    itemsConfig.push({
      key: '6',
      label: (
        <Link id="config_tiers" to={`/configurations/tiers`}>
          {t('tier.tierTitle')}
        </Link>
      ),
    })
  }

  function currentMenuConfig(name: string): string {
    if (!itemsConfig) return ''
    switch (name) {
      case Page.CLIENT:
        return itemsConfig[0]?.key as string
      case Page.SOCIETE:
        return itemsConfig[1]?.key as string
      case Page.UTILISATEUR:
        return itemsConfig[2]?.key as string
      case Page.REFERENCE:
        return itemsConfig[3]?.key as string
      case Page.TIER:
        return itemsConfig.find((item) => item && item.key === '6')
          ?.key as string
      default: {
        return ''
      }
    }
  }

  const USERNAME_MAX_DIGITS = 20

  const { version } = packageInfo

  const dispatch = useDispatch()
  const handleOnSelect = (value: any) => {
    switch (value.key) {
      case 'Parametres':
        //TODO: Route to profile
        break
      case 'Deconnexion':
        //TODO: Logout the system
        logout()
        dispatch(clearClient())
        break
      case 'Version':
        break
      default:
        break
    }
  }

  const currentMenuStock = (name: string): string => {
    if (!items) return ''
    switch (name) {
      case ModalName.FOURNISSEUR:
      case ModalName.ATTENDU: {
        return items[1]?.key as string
      }
      case ModalName.DESTINATAIRE:
      case ModalName.TRANSPORTEUR:
      case ModalName.COMMANDE: {
        return items[2]?.key as string
      }
      case ModalName.STOCK: {
        return items[0]?.key as string
      }
      case ModalName.MISSION: {
        return items[3]?.key as string
      }
      case ModalName.HISTORY: {
        return items[4]?.key as string
      }
      default: {
        return ''
      }
    }
  }

  useChangeStockHook()

  const isAccountValid = active && isWMSPermission
  const isNavItemValid = (condition: boolean) => {
    if (!isAccountValid) {
      return false
    }
    return !isPermissionEnabled() || isSuperAdmin() || condition
  }

  const canShowGestionDeStock = isNavItemValid(items.length > 0)
  const canShowConfigurations = isNavItemValid(itemsConfig.length > 0)
  const canShowBilling = isNavItemValid(canAccessBilling)
  const canShowInventory = isNavItemValid(canAccessInventory)
  const canShowParametres = isNavItemValid(canAccessParametres)

  return (
    <div className="bg-white">
      <div className="header h-16 flex justify-between bg-primary items-center">
        <span className="flex items-center">
          <img src={LOGO} alt="logo" className="p-5 h-10" />
          {isAccountValid && (
            <Link
              id="tableaudebord_menu"
              to={'/tableau-de-bord'}
              className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
                rootPage === RootPage.TABLEAU_DE_BORD ? 'active' : ''
              } `}
            >
              Tableau de bord
            </Link>
          )}
          {canShowGestionDeStock && (
            <Dropdown
              prefixCls="customize-dropdown-antd"
              menu={{
                items,
                selectable: true,
                defaultSelectedKeys: [''],
                selectedKeys: [currentMenuStock(name)],
              }}
              dropdownRender={(menu) => {
                return (
                  <div className={`bg-cl-primary bd-bot-header-dropdown`}>
                    {menu}
                  </div>
                )
              }}
            >
              <div id="gestiondeStock_menu" className="py-4">
                <p
                  className={`text-white mx-5 my-0 3xl:mx-10 menu-item no-underline ${
                    rootPage === RootPage.GESTION_DE_STOCK ? 'active' : ''
                  }`}
                >
                  Gestion de Stock
                </p>
              </div>
            </Dropdown>
          )}
          {canShowBilling && (
            <Link
              id="facturation_menu"
              to={'/facturation'}
              className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
                rootPage === RootPage.FACTURATION ? 'active' : ''
              }`}
            >
              Facturation
            </Link>
          )}
          {canShowInventory && (
            <Link
              id="inventaire_menu"
              to={'/inventaire'}
              className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
                rootPage === RootPage.INVENTAIRE ? 'active' : ''
              }`}
            >
              Inventaire
            </Link>
          )}
          {canShowConfigurations && (
            <Dropdown
              prefixCls="customize-dropdown-antd"
              menu={{
                items: itemsConfig,
                selectable: true,
                defaultSelectedKeys: [''],
                selectedKeys: [currentMenuConfig(currentPage)],
              }}
              dropdownRender={(menu) => {
                return (
                  <div className={`bg-cl-primary bd-bot-header-dropdown`}>
                    {menu}
                  </div>
                )
              }}
            >
              <div id="configurations_menu" className="py-4">
                <p
                  className={`text-white mx-5 my-0 3xl:mx-10 menu-item no-underline ${
                    rootPage === RootPage.CONFIGURATIONS ? 'active' : ''
                  }`}
                >
                  Configurations
                </p>
              </div>
            </Dropdown>
          )}

          {canShowParametres && (
            <Link
              id="parametres_menu"
              to={'/parametres'}
              className={`text-white mx-5 3xl:mx-10 menu-item no-underline ${
                rootPage === RootPage.PARAMETRES ? 'active' : ''
              } --${rootPage}`}
            >
              {t('parametres')}
            </Link>
          )}
        </span>
        <div className="inline-flex items-start">
          {/*<div className="inline-block pr-6">*/}
          {/*  <NotiBell />*/}
          {/*</div>*/}

          <SelectTooltip
            title={
              username && username?.length > USERNAME_MAX_DIGITS ? username : ''
            }
          >
            <Select
              className="nav-select"
              showArrow={false}
              placeholder={
                <>
                  <div className="flex items-center" id="account">
                    <span className="bg-white rounded-full p-0.5 mr-2">
                      <i className="icon-avatar"></i>
                    </span>

                    {username && (
                      <>{renderDigitsByNumber(username, USERNAME_MAX_DIGITS)}</>
                    )}
                  </div>
                </>
              }
              style={{ width: 230 }}
              bordered={false}
              labelInValue
              dropdownStyle={{
                borderRadius: 0,
              }}
              onSelect={handleOnSelect}
              options={[
                {
                  value: 'Parametres',
                  label: (
                    <div
                      className="flex items-center border-gray1 border-b border-solid border-0 pb-3"
                      id="account_parameter"
                    >
                      <i className="nav-dropdown icon-config"></i>
                      Paramètres
                    </div>
                  ),
                },
                {
                  value: 'Deconnexion',
                  label: (
                    <div
                      className="flex items-center border-gray1 border-b border-solid border-0 pb-3"
                      id="logout"
                    >
                      <i className="nav-dropdown icon-logout"></i>
                      Déconnexion
                    </div>
                  ),
                },
                {
                  disabled: true,
                  value: 'Version',
                  label: (
                    <div
                      className="flex items-center border-b-stone-400"
                      id="version"
                    >
                      {version}
                      {process.env.REACT_APP_BUILD_VERSION
                        ? ` - ${process.env.REACT_APP_BUILD_VERSION}`
                        : ''}
                    </div>
                  ),
                },
              ]}
            />
          </SelectTooltip>
        </div>
      </div>
    </div>
  )
}

const SelectWrapper = ({ onMouseEnter, onMouseLeave, ...rest }: any) => {
  const ref = useRef()

  useEffect(() => {
    const span = ref.current as any
    span?.addEventListener('mouseenter', onMouseEnter)
    span.addEventListener('mouseleave', onMouseLeave)
    return () => {
      span.removeEventListener('mouseenter', onMouseEnter)
      span.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [onMouseEnter, onMouseLeave])
  return (
    <div
      ref={ref}
      style={{
        display: 'inline-block',
      }}
      {...rest}
    />
  )
}

const SelectTooltip = ({ children, ...rest }: any) => {
  return (
    <Tooltip {...rest}>
      <SelectWrapper>{children}</SelectWrapper>
    </Tooltip>
  )
}
